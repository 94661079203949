//@ts-check
import queryString from 'query-string';
import { isMobileOrTablet, ANDROID_STORE_LINK, IOS_STORE_LINK } from 'views/common/utils/general';
import { isProductionEnv } from 'common/utils';
import { getSearchParams } from 'common/location-utils';
import { getTrackingParamsToForwardToServer } from 'common/utm-utils';
import { removeEmptyKeys } from 'common/object-utils';
import storage, { ShowLeaveConversationDialogOnLogin } from 'storage';

let localRedirectUrl = '';

let externalRedirectUrl = null;

export function redirectToConversationOnLogin(conversationId) {
  localRedirectUrl = `/communication/${conversationId}`;

  storage.getSessionStorage().setItem(ShowLeaveConversationDialogOnLogin, 'true');
}

export function redirectToActionOnLogin(actionId, urlSearchParams) {
  const queryParamsString = queryString.stringify(urlSearchParams);
  localRedirectUrl = `/actions/${actionId}${queryParamsString ? `?${queryParamsString}` : ''}`;
  storage.getSessionStorage().removeItem(ShowLeaveConversationDialogOnLogin);
}

export function getLocalRedirectUrl() {
  return localRedirectUrl;
}

export function getExternalRedirectUrl() {
  return externalRedirectUrl;
}

let sponsoredBy = '';

export function setSponsoredBy(companyId: string) {
  sponsoredBy = companyId;
}

export function getSponsoredBy() {
  return sponsoredBy;
}

type IConversationParams = {
  conversationToken?: string;
  conversationSecret?: string;
  conversationId?: string;
  conversationAddWithoutInvite?: boolean;
};

let conversationParams: IConversationParams = { conversationAddWithoutInvite: true };

export function saveSignupParamsFromUrl() {
  const params = getSearchParams() as any;

  if (params.sponsoredBy) {
    sponsoredBy = params.sponsoredBy;
  }

  if (params.conversationToken) {
    setConversationParams({ conversationToken: params.conversationToken });
  }
  if (params.conversationSecret) {
    setConversationParams({ conversationToken: params.conversationSecret });
  }
  if (params.redirect_to) {
    externalRedirectUrl = params.redirect_to;
  }
}

export function setConversationParams(params: IConversationParams = {}) {
  conversationParams = { ...conversationParams, ...params };

  if (params.conversationId) {
    redirectToConversationOnLogin(params.conversationId);
  }
}

export function getConversationParams() {
  return conversationParams;
}

function getCurrentParams() {
  let params = {
    sponsoredBy: sponsoredBy,
    ...getConversationParams(),
    ...getTrackingParamsToForwardToServer()
  };
  return removeEmptyKeys(params);
}

function getCurrentParamsEncoded() {
  return queryString.stringify(getCurrentParams());
}

function getCurrentParamsString() {
  const fileeeParams = getCurrentParams();
  let fileeeParamsString = Object.keys(fileeeParams)
    .map((k) => {
      return k + '=' + fileeeParams[k];
    })
    .join('&');
  return fileeeParamsString;
}

function getAdjustBaseUrl() {
  return `https://yplg.adj.st/my.fileee.com/signup?adjust_t=t0okyb&adjust_campaign=${sponsoredBy}`;
}

function getEncodedDeepLink() {
  let adjustDeepLink = `fileee://my.fileee.com/signup?${getCurrentParamsString()}`;
  return encodeURIComponent(adjustDeepLink);
}

export function getAdjustLink() {
  if (isProductionEnv()) {
    let adjustUrl = `${getAdjustBaseUrl()}&adjust_deeplink=${getEncodedDeepLink()}&${getCurrentParamsEncoded()}`;
    return adjustUrl;
  } else {
    let adjustUrl = `fileee://my.workeee.de/signup?${getCurrentParamsEncoded()}`;
    return adjustUrl;
  }
}

export function getAndroidStoreLink() {
  if (isMobileOrTablet()) {
    let adjustUrl = `${getAdjustBaseUrl()}&adjust_deeplink=${getEncodedDeepLink()}&adjust_redirect_ios=https%3A%2F%2Fplay.google.com%2Fstore%2Fapps%2Fdetails%3Fid%3Dcom.fileee.android.simpleimport&${getCurrentParamsEncoded()}`;
    return adjustUrl;
  } else {
    return ANDROID_STORE_LINK;
  }
}

export function getIOSStoreLink() {
  if (isMobileOrTablet()) {
    let adjustUrl = `${getAdjustBaseUrl()}&adjust_deeplink=${getEncodedDeepLink()}&adjust_redirect_android=https%3A%2F%2Fitunes.apple.com%2Fapp%2Fapple-store%2Fid919114040&${getCurrentParamsEncoded()}`;
    return adjustUrl;
  } else {
    return IOS_STORE_LINK;
  }
}
